import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'

const AboutPage = () => {
    return (
        <Layout>
            <Head title="About"/>
            <h1>About</h1>
            <p>
                As you can probably figure out from the site, my name is Gustaf Liljegren. I am a full time developer by day and a hobby creative by night.
                I am passionate about new technology, user experience and photography.
            </p>
            <p>
                I got my masters degree in Media Technology and Engineering from Linköping University but I currently live in Stockholm where I work as a consultant for Netlight Consulting.
            </p>
        </Layout>
    )
}

export default AboutPage
